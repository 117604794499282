import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { FaFacebookF, FaTwitter,FaInstagram } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English"
  },
  {
    code: "fr",
    name: "French"
  },
  {
    code: "it",
    name: "Italian"
  },
  {
    code: "es",
    name: "Spanish"
  },
  {
    code: "hi",
    name: "Hindi"
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "15px",
    lineHeight: "21px",
    fontWeight: "600",
    borderRadius: 0,
    minWidth: "auto",
    textTransform: "uppercase",
    padding: "0px 12px",
    letterSpacing: "1px",
    textDecoration: "none",
    marginLeft: "15px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
      textAlign: "left",
      color: "#fff",
      fontSize: "14px",
      marginBottom: "30px",
    },
  
    "&.active-link": {
      color: "#f83ecf",
    },
    "&:hover": {
      color: "#f83ecf",
    },
  },

  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
   
  },
  drawerContainer: {
    padding: "20px",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& li":{
      listStyle:"none",
    },
  },
  drawericon: {
    color: "#fff",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  logoBox: {
    position: "absolute",
    top: 3,
    left: 0,
    [theme.breakpoints.down("sm")]: {
      position: "initial",
    },
    "& img": {
      width: "105px",
     
    },
  },
  mintBtn: {
    height: "100%",
    backgroundColor: "#C8FF00",
    color: "#fff",
    fontSize: "14px !important",
    fontWeight: "500",
    textTransform: "uppercase",
    boxShadow: "none",
    borderRadius: 0,
    minHeight: "50px",
    width: "120px",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      marginBottom: "30px",
      width: "100% !important",
    },
    "&:hover": {
      backgroundColor: "#6442f4",
      color: "#fff",
    },
  },
  
  accountBox: {
    "& button": {
      color: "#6442f4",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
  },
  headerbar: {
    border: "none",
    opacity: "1",
    [theme.breakpoints.down("sm")]: {
      backgroundcolor: "#fff",
    },
  },
}));

export default function Header() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);


  const {
    menuButton,
    toolbar,
    logoBox,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 991
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getDrawerChoices = () => {
   
      return (
        <>
         <li> <NavLink to = '/' className={`${menuButton} navlinkItem`} activeClassName="active-link">{t('menu.home')}</NavLink> </li>
         <li> <NavLink to = '/offers' className={`${menuButton} navlinkItem`} activeClassName="active-link">{t('menu.offer')}</NavLink> </li>
         <li> <NavLink to = '/collection' className={`${menuButton} navlinkItem`} activeClassName="active-link">{t('menu.collections')}</NavLink> </li>
         <li> <NavLink to = '/about' className={`${menuButton} navlinkItem`} activeClassName="active-link">{t('menu.about')}</NavLink> </li>
         {/* <li> <NavLink to = '/faq' className={`${menuButton} navlinkItem`} activeClassName="active-link">FAQ</NavLink> </li> */}
        </>
      );
  
  };
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <Grid
          container
          style={{ paddingLeft: "0px", alignItems: "center" }}
        >
         
          <Grid item xs={12} align="right">
          <Box className="menuLink" display="flex" justifyContent="flex-end" alignItems="center">{getMenuButtons()} {socialIcons} {Profile}</Box>
          
          </Grid>
         
        </Grid>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/footerlogo.png" alt="" />
            {getDrawerChoices()}
        
            {socialIcons}
          </div>
        </Drawer>

        <div>{mobileLogo}</div>

        <Grid container>
          <Grid item xs={10} sm={11} align="right">
            {" "}
            {Profile}
          </Grid>
          <Grid item xs={2} sm={1}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#f83ecf", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const femmecubatorLogo = (
    <Box className={logoBox}>
      <Link to="/">
      {history.location.pathname !== "/" ? <img src="/images/footerlogo.png" alt="" /> : <img src="/images/logo.png" alt="" />} 
      </Link>
    </Box>
  );
  const mobileLogo = (
    <Box className={logoBox}>
      <Link to="/">
      {history.location.pathname !== "/" ? <img src="/images/footerlogo.png" alt="" /> : <img src="/images/logo.png" alt="" />} 
      </Link>
    </Box>
  );

  const Profile = (
    <Box className="accountBox">
     
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick} style={{fontSize:"12px"}}
      >
        { t(`language.${currentLanguage?.name}`) } <AiFillCaretDown/>
      </IconButton>
    </Box>
  );
  const socialIcons = (
    <Box className="socailIcons">
     
      <IconButton>
        <a target="_blank" href="https://www.facebook.com/Ruby-Collection-106669218780935" rel="noopener noreferrer">
          <FaFacebookF/>
        </a>
      </IconButton>
      <IconButton>
        <a target="_blank" href="https://twitter.com/RubyCollection5" rel="noopener noreferrer">
          <FaTwitter/>
        </a>
      </IconButton>
      <IconButton>
        <a target="_blank" href="https://www.instagram.com/RubyCollection_Official" rel="noopener noreferrer">
          <FaInstagram/>
        </a>
      </IconButton>
    </Box>
  );

  const getMenuButtons = () => {
    
      return (
        <>
        
          <li> <NavLink to = '/' className={`${menuButton} navlinkItem`} activeClassName="active-link">{t('menu.home')}</NavLink> </li>
          <li> <NavLink to = '/offers' className={`${menuButton} navlinkItem`} activeClassName="active-link">{t('menu.offer')}</NavLink> </li>
          <li> <NavLink to = '/collection' className={`${menuButton} navlinkItem`} activeClassName="active-link">{t('menu.collection')}</NavLink> </li>
          <li> <NavLink to = '/about' className={`${menuButton} navlinkItem`} activeClassName="active-link">{t('menu.about')}</NavLink> </li>
    {/* <li> <NavLink to = '/faq' className={`${menuButton} navlinkItem`} activeClassName="active-link">FAQ</NavLink> </li> */}
        </>
      );
   
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        className={history.location.pathname !== "/" ? "headerBlack" : "headerWhite"} style={history.location.pathname !== "/" ? {backgroundColor: "rgba(0,0,0, 1)",} : {backgroundColor: "rgba(255,255,255, 1)",}}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        disableScrollLock={true}
        onClose={handleClose1}
        onClick={handleClose1}
      >
        
        {languages.map(({ code, name }) => (
          <MenuItem key={code}>
            {/*eslint-disable-next-line */}
            <a href="#" onClick={() => {
                i18next.changeLanguage(code);
              }}>{ t(`language.${name}`) }
            </a>
          </MenuItem>
        ))}
        
       
      </Menu>
    </>
  );
}
