import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "../src/scss/main.css";
import { Web3Provider } from "@ethersproject/providers";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { NetworkContextName } from "src/constants";
import { SettingsProvider } from 'src/context/SettingsContext';
import i18next from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import en_translation from "./assets/locales/en/translation.json";
import es_translation from "./assets/locales/es/translation.json";
import fr_translation from "./assets/locales/fr/translation.json";
import hi_translation from "./assets/locales/hi/translation.json";
import it_translation from "./assets/locales/it/translation.json";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);


i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "fr", "it", "es", "hi"],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"]
    },
    resources: {
      en: {
        translation: en_translation 
      },
      fr: {
        translation: fr_translation
      },
      es: {
        translation: es_translation
      },
      hi: {
        translation: hi_translation
      },
      it: {
        translation: it_translation
      },
  },
  });

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
    <SettingsProvider>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
    </SettingsProvider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
