import _ from "lodash";
import { colors, createTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiMenuItem: {
      root: {
        color: "#fff !important",
        fontWeight: "300",
        fontSize: "14px !important",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        padding: "15px",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "50px !important",
      },
      gutters: {
        padding: "0 !important",
      },
    },
    MuiSelect: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        paddingLeft: "15px",
        borderRadius: "18px",
      },
    },
    MuiInput: {
      root: {
        borderRadius: "10px",
      },
      underline: {
        "&:hover": {
          borderBottom: "none",
        },
        "&::before": {
          borderBottom: "none",
          display: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        "&.Mui-expanded": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: "0",
        backgroundcolor: "#fff",
        padding: "18px",
        "&.Mui-expanded": {
          margin: "0",
        },
      },
      root: {
        padding: "0",
      },
    },
    MuiAccordionDetails: {
      root: {
        backgroundcolor: "#fff",
        color: "#c8ff00",
      },
    },
    MuiInputBase: {
      root: {
        color: "#1f1e22 !important",
        background: "#fff !important",
      },
      input: {
        height: "2.1876em !important",
      },
    },
    MuiTab:{
      root:{
        color: "#fff !important",
        textAlign:"left",
      },
      wrapper:{
        display:"block",
      },
      textColorInherit:{
        opacity:"1",
        "&.Mui-selected":{
        color:"#F83ECF !important",
      },},
    },
    MuiTableCell: {
      sizeSmall: {
        "@media (max-width:768px)": {
          padding: "6px 0px 6px 10px",
        },
      },
      head: {
        backgroundColor: "#171717",
      },
      root: {
        borderBottom: " none",
      },
      body: {
        color: "#fff",
        fontWeight:"600",
      },
    },
    MuiFormGroup: {
      root: {
        display: "flex",
        flexWrap: "inherit",
        flexDirection: "row",
        justifyContent: "space-around",
      },
    },
    MuiFormControlLabel: {
      root: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: "1px",
        marginRight: "16px",
        verticalAlign: "middle",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "0px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "rgba(255, 255, 255, 0.54)",
        padding: "8px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "none",
        },
      },
      edgeEnd:{
        marginRight:"0px",
      },
    },
    MuiAvatar:{
      colorDefault:{
        color:"#000",
        backgroundColor:"#F83ECF",
      },
    },
    MuiButton: {
      root: {
        fontWeight: "300 !important",
        fontSize: "14px !important",
        minWidth: "auto",
      },
      outlinedPrimary: {
        color: "#fff",
        fontSize: "16px",
        fontWeight: "300",
        border: "1px solid rgb(200 255 0)",
        width: "100%",
        background: "#c8ff00",
        borderRadius: "50px",
        "&:hover": {
          backgroundColor: "#6442F4",
          border: "1px solid #6442F4",
          color: "#fff",
        },
      },
      textPrimary: {
        color: "#BFBFBF",
        fontWeight: 500,
        fontSize: "14px !important",
        lineHeight: "17px",
      },
      containedSizeSmall: {
        padding: "4px 10px",
        fontSize: "0.8125rem",
      },
      containedSizeLarge:{
        "@media (max-width:767px)": {
          padding: "4px 10px",
        fontSize: "0.8125rem",
        },
       
      },
       
        containedPrimary: {
          color: "#fff",
          borderRadius:"0px",
          boxShadow:
            "0 0 1px 0 rgb(0 0 0 / 70%), 0 2px 2px -2px rgb(0 0 0 / 50%)",
          backgroundColor: "#F83ECF",
        },
        containedSecondary: {
          color: "#000",
          borderRadius:"0px",
          fontWeight:"700",
          backgroundColor: "#F83ECF",
        },
     
      outlinedSecondary: {
        color: "#fff",
        backgroundColor: "#C8FF00",
        padding: "30px",
        "&:hover": {
          backgroundColor: "#6442F4",
          color: "#fff",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiPaper: {
      root: {
        color: "#e6e5e8",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#353438",
        borderRadius: "0px !important",
      },
      rounded: {
        borderRadius: "20px",
        "@media (max-width:767px)": {
          borderRadius: "5px",
        },
      },
    },

    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "rgb(218 221 207)",
        borderWidth: 2,
      },
      root: {
        position: "relative",
        borderRadius: "13px",
        background: "#fff !important",
      },
      inputAdornedEnd: {
        "@media (max-width:767px)": {
          padding: "6px 8px",
          fontSize: "10px",
        },
      },
      inputAdornedStart: {
        paddingLeft: 14,
      },
      input: {
        padding: "11px 14px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "none",
        },
        "@media (max-width:767px)": {
          padding: " 6px 8px",
          fontSize: "10px",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "none",
        paddingBottom: "none",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiTypography:{
        root:{color:"#fff",}
      },
      MuiInputBase: {
        root: {
          backgroundColor: " #fff !important",
        },
        input: {
          height: "2.1876em !important",
          "&::placeholder": {
            opacity: 1,
            color: "#a1a1a1",
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "#5D29FF",
        light: "#fff",
        dark: "#000",
        paper: "#ECECEC",
      },
      primary: {
        main: "#F83ECF",
        blueMain: "#5D29FF",
      },
      secondary: {
        main: "#F83ECF",
      },
      white: {
        main: "#000",
      },
      text: {
        primary:"#fff",
        secondary:"#000",
        white: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#101010;",
        dark: "#1c2025",
        paper: "#242526",
      },
      primary: {
        main: "#1f73b7",
      },
      secondary: {
        main: "#E31A89",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
      },
    },
    shadows: strongShadows,
  },
];

export const createMUITheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    // console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
