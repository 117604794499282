import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/collection",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Collections")),
  },

  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/AboutUs/Index")),
  },
  {
    exact: true,
    path: "/details/:name",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftDetails/Index")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy")),
  },
  {
    exact: true,
    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsCondition")),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FAQ/FAQs")),
  },
  {
    exact: true,
    path: "/offers",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Offers")),
  },
 
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
