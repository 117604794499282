import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import TextField from "@material-ui/core/TextField";
import {} from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#000",
    position: "relative",
    padding: "50px 0px",
    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      color: "#2f086a",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "0px",
      marginTop: "10px",
      fontWeight: "300",
      fontSize: "18px",
      lineHeight: "18px",
      color: "#fff",
    },
  },
  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#fff",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    minWidth: "100px",
    borderRadius: "50px",
    position: "absolute",
    top: "5px",
    right: "5px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "#040405",
    "&:hover": {
      background: "#040405",
      color: "#fff",
    },
  },
  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  footerBgImg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: "1",
  },
  textFild: {
    position: "relative",

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4D4B53",
    borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
borderRadius:"50px",
background:" #4D4B53 !important",
    },
    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: theme.palette.primary.main,
      minWidth: "157px",
      fontSize: "14px",
      fontWeight: "300",
      color: theme.palette.background.dark,
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        position: "inherit",
        marginTop: "10px",
      },
    },
  },
  footertext: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    "& a": {
      color: "#fff",
      fontWeight: 500,
      textDecoration: "none",
      fontSize: "14px",
      display: "flex",
      textTransform: "capitalize",
      marginBottom: "10px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "15px",
      },
      "&:hover": {
        color: "#6442F4",
        textDecoration: "none",
      },
    },
  },
  socialmedia: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    "& svg": {
      fontSize: "30px",
    },
  },
  footernameBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
     },
  },
  textcenter:{
    [theme.breakpoints.down("xs")]: {
     textAlign:"center",
     marginBottom:"7px",
    },
  },
  iconsColor:{
    color:theme.palette.primary.main,
    marginRight:"10px",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Box
        className={classes.footerSection}
        style={{ backgroundImage: "url('./images/footerIMG.png')" }}
      >
        <Box
          style={{ margin: "20px 10px 0", position: "relative", zIndex: "2" }}
        >
          <Container maxWidth="lg">
            <Grid container justifyContent="space-around" spacing={1}>
            

              <Grid item xs={12} sm={7} md={6} align="left">
                <Box className={classes.footernameBox}>
                  <img alt="" src="images/footerlogo.png" />
                 
                </Box>
                <Box mt={4} className={classes.textcenter}>
                  <Typography variant="body2" style={{ color: "#fff" }}>
                    { t('footer_menu.subscribe_text') }
                  </Typography>
                </Box>

                <Box mt={1} className={classes.textFild}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder={ t("footer_menu.email_placeholder") }
                  />
                  <Button style={{ fontWeight: "600" }}>{ t('footer_menu.subscribe') }</Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1} md={2} align="left"></Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Box className={classes.footertext}>
                  <ScrollLink
                    smooth={true}
                    duration={500}
                    onClick={() => history.push("/about")}
                    to="/about"
                  >
                    { t('footer_menu.about_gianni') }
                  </ScrollLink>
                  <ScrollLink
                    smooth={true}
                    duration={500}
                    style={{ scrollTop: "0" }}
                    onClick={() => history.push("/collection")}
                    to="/collection"
                  >
                    { t('footer_menu.collections') }
                  </ScrollLink>
                  <ScrollLink
                    smooth={true}
                    style={{ scrollTop: "0" }}
                    duration={500}
                    to="/terms-condition"
                    onClick={() => history.push("/terms-condition")}
                  >
                    { t('footer_menu.terms_conditions') }
                  </ScrollLink>
                  <Link
                    to="/privacy"
                  >
                    {" "}
                    { t('footer_menu.privacy_policy') }
                  </Link>               
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={2} className="buttonFooter">
                <Button variant="text" href="https://www.facebook.com/Ruby-Collection-106669218780935" target="_blank" rel="noopener noreferrer" startIcon={<FaFacebookF fontSize="inherit" className={classes.iconsColor} />}>{ t('footer_menu.facebook') }</Button>
                <Button variant="text" href="https://twitter.com/RubyCollection5" target="_blank" rel="noopener noreferrer" startIcon={<FaTwitter fontSize="inherit" className={classes.iconsColor} />}>{ t('footer_menu.twitter')}</Button>
                <Button variant="text" href="https://www.instagram.com/RubyCollection_Official" rel="noopener noreferrer" target="_blank" startIcon={<FaInstagram fontSize="inherit" className={classes.iconsColor} />}>{ t('footer_menu.instagram') }</Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
