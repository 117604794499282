export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 42;
export const tokenContract = "0xE4861c8C1A80250e1e280F7F7bbf84736146b867";
export const MarketplaceContract = "0xf5DE7F4Ee0C4063a1e6f139fEa2eB92c2D153653";
export const NftTokenAddress = "0x4846666e4013A48647be98AF3FDE33251e679bd2";

export const CategoryButtons = [
  {
    name: "Hot & Top Sellers",
  },
  {
    name: "Gaming Items",
  },
  {
    name: "Metaverse",
  },
  {
    name: "Art",
  },
  {
    name: "Videos",
  },
  {
    name: "Letter of wishes",
  },
  {
    name: "Web 3.0",
  },
];
